<template>
  <span class="cursor-pointer" @click="showPresetModal = true">
    {{ displayText }}
  </span>

  <preset-modal
    v-if="showPresetModal"
    :close="() => (showPresetModal = false)"
    :preset-data="model"
    is-edit-mode
  />
</template>
<script setup lang="ts">
import { type PropType, computed, ref } from "vue";
import type { PermissionsPreset } from "@/models/permissionsPreset";
import { truncateWithEllipsis } from "@/helpers/formatting";

import PresetModal from "@/components/presets/presetModal/PresetModal.vue";

const props = defineProps({
  model: {
    type: Object as PropType<PermissionsPreset>,
    required: true
  }
});

const showPresetModal = ref(false);

const displayText = computed(() => {
  return truncateWithEllipsis(props.model.name);
});
</script>
